import "./malfunctionWindow.css"

import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css"

import { useState, useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"

import { CreateMalfunction, UpdateMalfunction, DeleteMalfunction } from "../../../../actions/malfunction"

import { Add, Close, DeleteOutline, Edit } from "@material-ui/icons"

import SiteNameInput from "./SiteNameInput/SiteNameInput"
import MalfunctionSideNote from "./MalfunctionSideNote/MalfunctionSideNote"

export default function MalfunctionWindow({ sortedMalfunctionGroup, mokedWorkersName, setOpenWindow, chosenMalfunction, setChosenMalfunction, setMalfunctionUpdater, malfunctionType }) {

    const [ siteNameValue, setSiteNameValue ] = useState("")
    const [ workerName, setWorkerName ] = useState("")
    const [ malfunctionSort, setMalfunctionSort ] = useState("")
    const [ malfunctionProblem, setMalfunctionProblem ] = useState("")
    const [ malfunctionDate, setMalfunctionDate ] = useState("")
    const [ malfunctionLocalDate, setMalfunctionLocalDate ] = useState("")
    const [ malfunctionSideNote, setMalfunctionSideNote ] = useState()

    const dispatch = useDispatch()

    const chosenMalfunctionId = useSelector(state => state.malfunctions.find(mal => mal._id === chosenMalfunction))

    const siteInfoType = sortedMalfunctionGroup.find(site => site.siteName === siteNameValue)?.type || ''

    const [ siteMalfunction, setSiteMalfunction ] = useState({
        siteNameValue: "",
        workerName: "",
        malfunctionSort: "",
        malfunctionProblem: "",
        malfunctionLocation: "",
        callNumber: "",
        sideNote: [],
        created_At: "",
        malfunctionColor: "",
        type: siteInfoType,
    })

    useEffect(() => {

        if(chosenMalfunctionId){
            setSiteMalfunction(prev => ({
                ...prev,
                malfunctionLocation: chosenMalfunctionId.malfunctionLocation,
                callNumber: chosenMalfunctionId.callNumber,
                sideNote: chosenMalfunctionId.sideNote,
                created_At: chosenMalfunctionId.created_At,
                malfunctionColor: chosenMalfunctionId.malfunctionColor,
                type: chosenMalfunctionId.type || siteInfoType,
            }))

            setMalfunctionLocalDate(chosenMalfunctionId.created_At)

            setSiteNameValue(chosenMalfunctionId.siteNameValue)
            setWorkerName(chosenMalfunctionId.workerName)
            setMalfunctionSort(chosenMalfunctionId.malfunctionSort)
            setMalfunctionProblem(chosenMalfunctionId.malfunctionProblem)
            setMalfunctionSideNote(chosenMalfunctionId.sideNote)
        }

    }, [ chosenMalfunctionId ])

    useEffect(() => {

        let now = new Date(malfunctionLocalDate);

        const options = {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "2-digit",
            timeZone: 'Asia/Jerusalem',
            hour12: false,
            hour: '2-digit',
            minute:'2-digit',
            second: '2-digit' 
        }
    
        let created_At = now.toLocaleString('en-US', options).replace(/,/g,"")

        setMalfunctionDate(created_At)

    }, [malfunctionLocalDate])

    useEffect(() => {

        setSiteMalfunction(prev => ({
            ...prev,
            siteNameValue: siteNameValue,
            workerName: workerName,
            malfunctionSort: malfunctionSort,
            malfunctionProblem: malfunctionProblem,
            created_At: malfunctionDate,
            sideNote: malfunctionSideNote
        }))

    }, [ siteNameValue, workerName, malfunctionSort, malfunctionProblem, malfunctionDate, malfunctionSideNote ])

    const siteName = sortedMalfunctionGroup.map(site => {
        return site.siteName
    })

    const userName = mokedWorkersName.map(name => {
        return name.userName
    })

    const MalfunctionWith = malfunctionType.map(malfunction => {
        return malfunction.malfunctionWith
    })

    let WhatIsWrong = malfunctionType.filter(problem => problem.malfunctionWith === malfunctionSort)

    if(Object.keys(WhatIsWrong).length > 0){

        WhatIsWrong = WhatIsWrong[0].whatIsWrong

    }

    const AddMalfunction= () => {

        dispatch(CreateMalfunction(siteMalfunction))

        Clear()

    }

    const EditMalfunction = () => {

        dispatch(UpdateMalfunction(chosenMalfunction, siteMalfunction))

        setMalfunctionUpdater(prev => !prev)

        Clear()
    }

    const RemoveMalfunction = () => {

        dispatch(DeleteMalfunction(chosenMalfunction))

        setMalfunctionUpdater(prev => !prev)

        Clear()
        
    }

    const Clear = () => {

        setSiteMalfunction({
            siteNameValue: "",
            workerName: "",
            malfunctionSort: "",
            malfunctionProblem: "",
            malfunctionLocation: "",
            callNumber: "",
            sideNote: "",
            created_At: "",
            malfunctionColor: ""
        })

        setChosenMalfunction("")

        setOpenWindow(false)

    }

    return (
        <div className="malfunctionWindow fadeInDown"> 
            <div className="malfunctionWindowInner fadeIn">
                <button className="closeButton" 
                onClick={() =>{

                    setOpenWindow(false)
                    Clear()

                 }} >
                <Close className="closeButtonIcon"/></button>
                <div className="malfunctionWindowFormInput">
                    <div className="malfunctionWindowName">
                        <SiteNameInput searchGroup={siteName} wantedValue={siteNameValue} setWantedValue={setSiteNameValue} placeHolder="הכנס את שם האתר המבוקש" />
                        <SiteNameInput searchGroup={MalfunctionWith} wantedValue={malfunctionSort} setWantedValue={setMalfunctionSort} placeHolder="הכנס את סוג התקלה" />
                        <div className="siteNameInput">
                            <input type="text" placeholder="אזור / מספר" className="entryFormInput entryFormInputEmployList inputWindow" value={siteMalfunction.malfunctionLocation}
                             onChange={(e) => setSiteMalfunction((prev) => ({...prev, malfunctionLocation:e.target.value})) } />
                        </div>
                    </div>
                    <div className="malfunctionWindowComment">
                        <SiteNameInput searchGroup={userName} wantedValue={workerName} setWantedValue={setWorkerName} placeHolder="הכנס את שם מוקדן" />
                        <SiteNameInput searchGroup={WhatIsWrong} wantedValue={malfunctionProblem} setWantedValue={setMalfunctionProblem} placeHolder="פירוט סוג התקלה" />
                        <div className="siteNameInput">
                            <input type="text" placeholder="מספר קריאה (אם יש)" className="entryFormInput entryFormInputEmployList inputWindow" value={siteMalfunction.callNumber}
                             onChange={(e) => setSiteMalfunction((prev) => ({...prev, callNumber:e.target.value})) } />
                        </div>
                    </div>
                    {chosenMalfunctionId &&
                        <div className="malfunctionWindowComment">
                            <div className="siteNameInput">
                                    <input type="dateTime-local" placeholder="תאריך התקלה" className="entryFormInput entryFormInputEmployList inputWindow" value={malfunctionLocalDate}
                                    onChange={(e) => setMalfunctionLocalDate(e.target.value)} />
                            </div>
                        </div>
                    }
                    <div className="addMalfunctionContainer">
                        {chosenMalfunction ?
                        <>
                        <button className="editMalfunction" onClick={EditMalfunction}><Edit className="editMalfunctionIcon"/></button>
                        <button className="removeMalfunction" onClick={() => {

                            confirmAlert({
                                title: <p className="approveTitle">אשר לביצוע הפעולה</p>,
                                message: <p className="approveMessage"> האם אתה בטוח שברצונך למחוק תקלה זאת?</p>,
                                buttons: [
                                {
                                    label: <p>בטוח</p>,
                                    onClick: () => {

                                        RemoveMalfunction()
                                    }
                                },
                                {
                                    label: <p>בטל</p>
                                }
                                ]
                            })

                            }}><DeleteOutline className="removeMalfunctionIcon"/></button>
                        </>
                        : <button className="addNewMalfunction" onClick={AddMalfunction}><Add className="addNewMalfunctionIcon"/></button>}
                    </div>
                </div>
                <div className="siteNameInputSideNote">
                    <MalfunctionSideNote malfunctionSideNote={malfunctionSideNote} setMalfunctionSideNote={setMalfunctionSideNote} /> 
                    <div className="colorPickerMalfunction" >
                        <label className="malfunctionLabel" >בחר צבע תקלה</label>
                        <input type="color" value={siteMalfunction.malfunctionColor}  onChange={(e) => setSiteMalfunction((prev) => ({...prev, malfunctionColor:e.target.value})) }/>
                    </div>
                </div>
            </div>
        </div>
    )
}
