import "./entryForm.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateActiveEntry,
  UpdateActiveEntry,
  DeleteActiveEntry,
} from "../../actions/activeEntries";
import EmployList from "./EmployList/EmployList";
import CreatorName from "./CreatorName/CreatorName";
import SiteNameInput from "./SiteNameInput/SiteNameInput";
import Validation from "./Validation";

import { Alert } from "@material-ui/lab";
import { Done, Close, MeetingRoom, Create } from "@material-ui/icons";

export default function EntryForm({
  sitesInformation,
  siteNameValue,
  setSiteNameValue,
  activeEntryEditId,
  setActiveEntryEditId,
  setGetActiveEntriesUpdater,
  mokedWorkersName,
}) {
  const [isApprove, setIsApprove] = useState(false);
  const [entryAlert, setEntryAlert] = useState(false);
  const [editAlert, setEditAlert] = useState(false);
  const [fill, setFill] = useState(false);
  const [errors, setErrors] = useState({ 1: 1 });

  const [entryData, setEntryData] = useState({
    siteName: "",
    companyName: "",
    employName: "",
    creator: "",
    approve: "",
    sideNote: "",
    mokedType: "",
    type: "",
  });

  const [creatorNameValue, setCreatorNameValue] = useState("");

  const [employArray, setEmployArray] = useState([]);

  const dispatch = useDispatch();

  const editActiveEntry = useSelector(
    (state) =>
      activeEntryEditId &&
      state.activeEntries.find((e) => e._id === activeEntryEditId)
  );

  useEffect(() => {
    if (editActiveEntry) {
      setEntryData(() => ({
        siteName: editActiveEntry.siteName,
        companyName: editActiveEntry.companyName,
        employName: employArray[0],
        approve: editActiveEntry.approve,
        creator: editActiveEntry.creator,
        sideNote: editActiveEntry.sideNote,
        type:
          sitesInformation
            ?.filter((e) => e.siteName === editActiveEntry.siteName)
            .map((m) => m?.type)
            .toString() || "",
        mokedType: sitesInformation
          ?.filter((e) => e.siteName === editActiveEntry.siteName)
          .map((m) => m.mokedType)
          .toString(),
      }));

      setSiteNameValue(editActiveEntry.siteName);

      setCreatorNameValue(editActiveEntry.creator);
    }
  }, [editActiveEntry, sitesInformation, setSiteNameValue, employArray]);

  useEffect(() => {
    setEntryData((prev) => ({
      ...prev,
      siteName: siteNameValue,
      creator: creatorNameValue,
      mokedType: sitesInformation
        ?.filter((e) => e.siteName === siteNameValue)
        .map((m) => m.mokedType)
        .toString(),
      type:
        sitesInformation
          ?.filter((e) => e.siteName === siteNameValue)
          .map((m) => m?.type)
          .toString() || "",
    }));
  }, [siteNameValue, creatorNameValue, sitesInformation, employArray]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setFill(true);

    if (Object.keys(errors).length === 0) {
      if (activeEntryEditId) {
        dispatch(UpdateActiveEntry(activeEntryEditId, entryData));

        setEditAlert(true);

        setTimeout(stopAlert, 3000);
      } else {
        let activeEntryData = entryData;

        let filteredData = employArray.filter((cl) => cl !== "");

        filteredData.map((em) => {
          return (
            (activeEntryData.employName = em),
            dispatch(CreateActiveEntry(activeEntryData))
          );
        });

        setEntryAlert(true);

        setTimeout(stopAlert, 3000);
      }

      Clear();

      setFill(false);

      setGetActiveEntriesUpdater(false);
    }
  };

  useEffect(() => {
    setErrors(Validation(entryData, employArray));
  }, [entryData, employArray]);

  const Clear = () => {
    setEntryData({
      siteName: "",
      companyName: "",
      employName: "",
      creator: "",
      approve: "",
      sideNote: "",
      mokedType: "",
      type: "",
    });

    setSiteNameValue("");

    setCreatorNameValue("");

    setEmployArray([]);

    setActiveEntryEditId("");

    setIsApprove(false);

    setErrors({ 1: 1 });
  };

  const stopAlert = () => {
    setEntryAlert(false);
    setEditAlert(false);
  };

  return (
    <div className="entryFormContainer">
      <div className="entryFormWrapper">
        <div className="entryFormTop">
          <div className="messagePopUp">
            {entryAlert && (
              <Alert severity="success">הכניסה נרשמה בהצלחה</Alert>
            )}
            {editAlert && <Alert severity="info">הכניסה עודכנה בהצלחה</Alert>}
          </div>
          {!activeEntryEditId ? (
            <div className="entryFormTopStatus">
              {" "}
              <p>
                מצב כניסה
              </p> <MeetingRoom className="formStatusIconEnter" />{" "}
            </div>
          ) : (
            <div className="entryFormTopStatus">
              {" "}
              <p>מצב עריכה</p> <Create className="formStatusIconEdit" />{" "}
            </div>
          )}
        </div>
        <div className="entryFormBottom">
          <div className="entryFormBottomRight">
            {activeEntryEditId && (
              <div className="entryFormBottomRightButtons">
                <button
                  className="entryFormBottomRightButtonsBack"
                  onClick={() => Clear()}
                >
                  חזרה{" "}
                </button>
                <button
                  className="entryFormBottomRightButtonsDelete"
                  onClick={() => {
                    dispatch(DeleteActiveEntry(activeEntryEditId));
                    Clear();
                  }}
                >
                  מחק כניסה{" "}
                </button>
              </div>
            )}
          </div>
          <div className="entryFormBottomLeft">
            <form className="entryForm" onSubmit={handleSubmit}>
              <label className="entryFormLabel">שם האתר:</label>
              <SiteNameInput
                sitesInformation={sitesInformation}
                setSiteNameValue={setSiteNameValue}
                siteNameValue={siteNameValue}
              />
              {fill && errors.siteName && (
                <p className="emptyField">{errors.siteName}</p>
              )}

              <label className="entryFormLabel">שם החברה:</label>
              <input
                type="text"
                placeholder="הכנס את שם החברה"
                className="entryFormInput"
                value={entryData.companyName}
                onChange={(e) =>
                  setEntryData((prev) => ({
                    ...prev,
                    companyName: e.target.value,
                  }))
                }
              />
              {fill && errors.companyName && (
                <p className="emptyField">{errors.companyName}</p>
              )}

              <label className="entryFormLabel">רשימת כניסה:</label>
              <EmployList
                employArray={employArray}
                setEmployArray={setEmployArray}
                activeEntryEditId={activeEntryEditId}
              />
              {fill && errors.employName && (
                <p className="emptyField">{errors.employName}</p>
              )}

              <label className="entryFormLabel approvedLabel">
                יש אישור כניסה/ מופיע בסידור עבודה
                <button
                  type="button"
                  className="isApprovedIcon"
                  onClick={(e) => {
                    e.preventDefault();
                    return (
                      setIsApprove(true),
                      setEntryData((prev) => ({ ...prev, approve: "יש אישור" }))
                    );
                  }}
                >
                  <Done className="approvedIcon" />
                </button>
                <button
                  type="button"
                  className="notApprovedIcon"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsApprove(false);
                  }}
                >
                  <Close className="approvedIcon" />
                </button>
              </label>
              {!isApprove && (
                <input
                  type="text"
                  placeholder="הכנס את שם מאשר הכניסה"
                  className="entryFormInput"
                  onChange={(e) =>
                    setEntryData((prev) => ({
                      ...prev,
                      approve: e.target.value,
                    }))
                  }
                  value={entryData.approve}
                />
              )}
              {fill && errors.approve && (
                <p className="emptyField">{errors.approve}</p>
              )}

              <label className="entryFormLabel">שם המוקדן:</label>
              <CreatorName
                setCreatorNameValue={setCreatorNameValue}
                creatorNameValue={creatorNameValue}
                mokedWorkersName={mokedWorkersName}
              />
              {fill && errors.creator && (
                <p className="emptyField">{errors.creator}</p>
              )}

              <label className="entryFormLabel">הערות:</label>
              <input
                type="text"
                placeholder="הוסף הערה לכניסה"
                className="entryFormInput"
                value={entryData.sideNote}
                onChange={(e) =>
                  setEntryData((prev) => ({
                    ...prev,
                    sideNote: e.target.value,
                  }))
                }
              />

              {!activeEntryEditId ? (
                <button type="submit" className="entryFormBtn">
                  רשום כניסה
                </button>
              ) : (
                <button type="submit" className="editEntryFormBtn">
                  ערוך כניסה
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
