import { saveAs } from "file-saver"
import XLSX from 'sheetjs-style'

export default function MalfunctionExcelExport(props) {

        const {entries, fileName} = props;

        const filteredEntries = entries.map((entry, index) => {
        
            return {
                שם_העובד: Array.isArray(entry.employName) ? entry.employName[0] : entry.employName,
                שם_החברה: entry?.companyName,
                שעת_כניסה: entry?.created_At?.slice(4,10)+" - "+entry?.created_At?.slice(16,21),
                שעת_יציאה: entry?.updated_At ? entry.updated_At?.slice(4,10)+" - "+entry.updated_At?.slice(16,21) : "",
                מאשר_הכניסה: entry?.approve,
                הערות: entry?.sideNote || "---",
                המוקדן_המכניס: entry?.creator,
            }
        })

        const formattedEntries = {
            siteName: fileName,
            filteredEntries
        }
    
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
    
        const exportToCSV = async () => {
            const wb = XLSX.utils.book_new(); // Create a new workbook

            const {filteredEntries, siteName} = formattedEntries
            const ws = XLSX.utils.json_to_sheet(filteredEntries); // Create a worksheet from the data array
            const sheetName = `${siteName}`; // Generate a name for the sheet
            ws['!cols'] = filteredEntries[0] ? Object.keys(filteredEntries[0]).map(key => {
                const maxLength = filteredEntries.reduce((max, item) => Math.max(max, (item[key] || '').length), 0);
                return { wch: maxLength < 15 ? 15 : maxLength };
            }) : [];
            Object.keys(ws).forEach((cell) => {
                if (cell[0] !== '!') { // Avoid special keys like '!cols', '!ref', etc.
                    ws[cell].s = {
                        alignment: { horizontal: 'right' },
                    };
                }
            });
            const header_cells = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1'];
            header_cells.forEach(cell => {
                ws[cell].s = { fill: { fgColor: { rgb: "D3D3D3" } } };
            });
            XLSX.utils.book_append_sheet(wb, ws, sheetName); // Append the worksheet to the workbook
        
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            saveAs(data, "דו״ח כניסות " + fileName + fileExtension);
        }
    
        return (
            <button className="downloadEntriesButton" onClick={() => exportToCSV()}>הורד קובץ כניסות Exl</button>
        )
    }
