import React, { useState } from "react";
import CloseScoutsInfo from "../../components/CloseScoutsInfo/CloseScoutsInfo";

function CloseScouts(props) {
  const { scoutInfo } = props;
  const [scoutType, setScoutType] = useState("");

  if (!scoutInfo.length) return <h2>אנא המתן...</h2>;

  const scoutByType = scoutInfo.reduce((acc, scout) => {
    acc[scout.type] = acc[scout.type] || [];
    acc[scout.type].push(scout);
    return acc;
  }, {});

  const ScourTypeChoose = () => {
    return (
      <div className="ScoutContainer">
        <h2>ארכיון סיורים</h2>
        <button
          className="ScoutChooseTypeButton"
          onClick={() => setScoutType("weeklyScout")}
        >
          סיור שבועי
        </button>
        <button
          className="ScoutChooseTypeButton"
          onClick={() => setScoutType("jumpScout")}
        >
          סיור הקפצה
        </button>
        <button
          className="ScoutChooseTypeButton"
          onClick={() => setScoutType("scoutLevelB")}
        >
          סיור מתקן רמה ב׳
        </button>
      </div>
    );
  };

  const scoutsTypeNames = {
    weeklyScout: "סיור שבועי",
    jumpScout: "סיור הקפצה",
    scoutLevelB: "סיור מתקן רמה ב׳",
  };

  const RenderCloseScoutsInfo = () => {
    return (
      <CloseScoutsInfo
        scouts={scoutByType[scoutType]}
        setScoutType={setScoutType}
        scoutsTypeNames={scoutsTypeNames}
      />
    );
  };

  return !scoutType ? ScourTypeChoose() : RenderCloseScoutsInfo();
}

export default CloseScouts;
