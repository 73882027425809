import EntryForm from "../EntryForm/EntryForm";

import { useState } from "react";
import ActiveEntryItem from "./ActiveEntriesItem/ActiveEntriesItem";
import ChosenMokedType from "./ChosenMokedType/ChosenMokedType";
import "./feed.css";

export default function Feed({
  sitesInformation,
  siteNameValue,
  setSiteNameValue,
  fetchActiveEntries,
  setPickedSite,
  activeEntryEditId,
  setActiveEntryEditId,
  setGetActiveEntriesUpdater,
  mokedWorkersName,
}) {
  const [chosenMokedType, setChosenMokedType] = useState("כל הכניסות");

  return (
    <div className="feed">
      <div className="feedWrapper">
        <EntryForm
          sitesInformation={sitesInformation}
          siteNameValue={siteNameValue}
          mokedWorkersName={mokedWorkersName}
          setSiteNameValue={setSiteNameValue}
          setPickedSite={setPickedSite}
          activeEntryEditId={activeEntryEditId}
          setActiveEntryEditId={setActiveEntryEditId}
          setGetActiveEntriesUpdater={setGetActiveEntriesUpdater}
        />
        <ChosenMokedType
          chosenMokedType={chosenMokedType}
          setChosenMokedType={setChosenMokedType}
        />
        <div className="activeEntryListItem">
          {fetchActiveEntries.map((activeEntry, index) => {
            return (
              <ActiveEntryItem
                key={index}
                activeEntry={activeEntry}
                chosenMokedType={chosenMokedType}
                setPickedSite={setPickedSite}
                setSiteNameValue={setSiteNameValue}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
