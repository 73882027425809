import "./malfunctions.css";

import { useState } from "react";

import { useSelector } from "react-redux";

import MalfunctionsRight from "../../components/MalfunctionsComponent/MalfunctionsRight/MalfunctionsRight";
import MalfunctionsMain from "../../components/MalfunctionsComponent/MalfunctionsMain/MalfunctionsMain";

export default function Malfunctions({
  sitesInformation,
  mokedWorkersName,
  setMalfunctionUpdater,
  malfunctionType,
}) {
  const [chosenGroup, setChosenGroup] = useState("");

  const allMalfunction = useSelector((state) => state.malfunctions);

  const isArchive = true;

  return (
    <div className="MalfunctionsContainer">
      <MalfunctionsRight
        sitesInformation={sitesInformation}
        setChosenGroup={setChosenGroup}
      />
      <MalfunctionsMain
        chosenGroup={chosenGroup}
        sitesInformation={sitesInformation}
        mokedWorkersName={mokedWorkersName}
        allMalfunction={allMalfunction}
        setMalfunctionUpdater={setMalfunctionUpdater}
        isArchive={isArchive}
        malfunctionType={malfunctionType}
      />
    </div>
  );
}
