import React, { useContext, useMemo } from "react";
import { Route, Switch } from "react-router-dom";

import AuthSignIn from "./pages/Auth/AuthSignIn";
import CheckList from "./pages/CheckList/CheckList";
import ClientEntries from "./pages/ClientEntries/ClientEntries";
import EntriesPage from "./pages/EntriesPage/EntriesPage";
import FarmInformation from "./pages/FarmInformation/FarmInformation";
import FinishedTransformsForms from "./pages/FinishedTransformsForms/FinishedTransformsForms";
import ImageLists from "./pages/ImageList/ImageList";
import MalfunctionArchive from "./pages/MalfunctionArchive/MalfunctionArchive";
import Malfunctions from "./pages/Malfunctions/Malfunctions";
import ManagerPlace from "./pages/ManagerPlace/ManagerPlace";
import MokedScans from "./pages/MokedScans/MokedScans";
import CreateAccount from "./pages/PhoneExp/CreateAccounts";
import Scouts from "./pages/Scouts/Scouts";
import TransformsForms from "./pages/TransformsForms/TransformsForms";

import { DataContext } from "./context/DataContext";
import CloseScouts from "./pages/CloseScouts/CloseScouts";

const Main = () => {
  const {
    setMokedWorkerUpdater,
    setSiteInfoUpdater,
    setMalfunctionUpdater,
    setMalfunctionTypeUpdater,
    setChatMessageUpdater,
    setMokedScansSchemaUpdater,
    setFarmInfoUpdater,
    setOtherSiteInfoUpdater,
    user,
    moked,
    client,
    mokedWorkersName,
    malfunctionType,
    sitesInformation,
    farmsInformation,
    otherSiteInformation,
    shiftPageType,
    imageList,
    scoutInfo,
    socket,
  } = useContext(DataContext);

  const params = useMemo(() => {
    return {
      entriesPage: {
        shiftPageType,
        mokedWorkersName,
        socket,
        sitesInformation,
        user: user?.result?.userName,
        setChatMessageUpdater,
      },
      managerPlace: {
        imageList,
        shiftPageType,
        malfunctionType,
        setMalfunctionTypeUpdater,
        setMokedScansSchemaUpdater,
        setMokedWorkerUpdater,
        setSiteInfoUpdater,
        setFarmInfoUpdater,
        farmsInformation,
        otherSiteInformation,
        setOtherSiteInfoUpdater,
      },
      transformsForms: {
        mokedWorkersName,
        socket,
        moked,
      },
      malfunctions: {
        sitesInformation,
        mokedWorkersName,
        setMalfunctionUpdater,
        malfunctionType,
      },
      malfunctionArchive: {
        sitesInformation,
        mokedWorkersName,
        setMalfunctionUpdater,
        moked,
      },
      scout: {
        mokedWorkersName,
        sitesInformation,
      },
    };
  }, [
    mokedWorkersName,
    sitesInformation,
    farmsInformation,
    malfunctionType,
    shiftPageType,
    imageList,
    user,
    socket,
    setChatMessageUpdater,
    setMokedScansSchemaUpdater,
    setMokedWorkerUpdater,
    setSiteInfoUpdater,
    setFarmInfoUpdater,
    setMalfunctionTypeUpdater,
    setMalfunctionUpdater,
    moked,
    otherSiteInformation,
    setOtherSiteInfoUpdater,
  ]);

  return (
    <main>
      <Switch>
        <Route exact path="/">
          {moked ? (
            <EntriesPage {...params.entriesPage} />
          ) : client?.userName ? (
            <MalfunctionArchive />
          ) : (
            <AuthSignIn />
          )}
        </Route>
        <Route exact path="/CheckList">
          {moked ? (
            <CheckList />
          ) : client?.userName ? (
            <MalfunctionArchive />
          ) : (
            <AuthSignIn />
          )}
        </Route>
        <Route exact path="/farmsInformation">
          {moked ? (
            <FarmInformation farmsInformation={farmsInformation} />
          ) : client?.userName ? (
            <MalfunctionArchive />
          ) : (
            <AuthSignIn />
          )}
        </Route>
        <Route exact path="/otherSitesInformation">
          {moked ? (
            <FarmInformation
              farmsInformation={otherSiteInformation}
              otherSite
            />
          ) : client?.userName ? (
            <MalfunctionArchive />
          ) : (
            <AuthSignIn />
          )}
        </Route>
        <Route exact path="/scouts">
          {moked ? (
            <Scouts {...params.scout} />
          ) : client?.userName ? (
            <MalfunctionArchive />
          ) : (
            <AuthSignIn />
          )}
        </Route>
        <Route exact path="/CloseScouts">
          {moked ? (
            <CloseScouts scoutInfo={scoutInfo} />
          ) : client?.userName ? (
            <MalfunctionArchive />
          ) : (
            <AuthSignIn />
          )}
        </Route>
        <Route exact path="/imageList">
          {moked ? (
            <ImageLists imageList={imageList} />
          ) : client?.userName ? (
            <MalfunctionArchive />
          ) : (
            <AuthSignIn />
          )}
        </Route>
        <Route exact path="/mokedScans">
          {moked ? (
            <MokedScans />
          ) : client?.userName ? (
            <MalfunctionArchive />
          ) : (
            <AuthSignIn />
          )}
        </Route>
        <Route exact path="/managerPlace">
          {user && user.result.userName === "מנהל" ? (
            <ManagerPlace {...params.managerPlace} />
          ) : (
            <AuthSignIn />
          )}
        </Route>
        <Route path="/AuthSignIn" component={AuthSignIn} />
        <Route path="/ClientEntries/:userName" component={ClientEntries} />
        <Route exact path="/Transforms">
          {moked || client.isTransform ? (
            <TransformsForms {...params.transformsForms} />
          ) : client.userName ? (
            <ClientEntries />
          ) : (
            <AuthSignIn />
          )}
        </Route>
        <Route exact path="/Malfunctions">
          {moked ? (
            <Malfunctions {...params.malfunctions} />
          ) : client.userName ? (
            <MalfunctionArchive {...params.malfunctionArchive} />
          ) : (
            <AuthSignIn />
          )}
        </Route>
        <Route exact path="/MalfunctionArchive">
          {moked ? (
            <MalfunctionArchive {...params.malfunctionArchive} />
          ) : client.userName ? (
            <MalfunctionArchive {...params.malfunctionArchive} />
          ) : (
            <AuthSignIn />
          )}
        </Route>
        <Route exact path="/TransformsForms">
          {moked || client.isTransform ? (
            <FinishedTransformsForms />
          ) : client.userName ? (
            <ClientEntries />
          ) : (
            <AuthSignIn />
          )}
        </Route>
        <Route exact path="/createAccount">
          {moked ? (
            <CreateAccount />
          ) : client.userName ? (
            <ClientEntries />
          ) : (
            <AuthSignIn />
          )}
        </Route>

        <Route exact path="*" component={AuthSignIn} />
      </Switch>
    </main>
  );
};

export default Main;
