import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FetchAllChatMessage } from "../actions/chatMessage";
import { FetchAllFarmsInformations } from "../actions/FarmsInformation";
import { FetchAllImageLists } from "../actions/ImageList";
import { FetchAllMalfunctions } from "../actions/malfunction";
import { FetchMalfunctionArchive } from "../actions/malfunctionArchive";
import { FetchAllMalfunctionTypes } from "../actions/malfunctionType";
import { FetchAllMokedScans } from "../actions/mokedScans";
import { FetchAllMokedScansSchemas } from "../actions/mokedScansSchema";

import { FetchAllMokedWorkers } from "../actions/mokedWorkers";
import { FetchAllShiftPageTypes } from "../actions/shiftPageType";
import { FetchSiteInformation } from "../actions/siteInformation";
import { FetchAllOtherSiteInformations } from "../actions/OtherSiteInformation";
import { FetchAllScouts } from "../actions/Scout";

export const useGetData = (user) => {
  const [mokedWorkerUpdater, setMokedWorkerUpdater] = useState(true);
  const [siteInfoUpdater, setSiteInfoUpdater] = useState(true);
  const [malfunctionUpdater, setMalfunctionUpdater] = useState(true);
  const [malfunctionTypeUpdater, setMalfunctionTypeUpdater] = useState(true);
  const [chatMessageUpdater, setChatMessageUpdater] = useState(true);
  const [mokedScansSchemaUpdater, setMokedScansSchemaUpdater] = useState(true);
  const [farmInfoUpdater, setFarmInfoUpdater] = useState(true);
  const [otherSiteInfoUpdater, setOtherSiteInfoUpdater] = useState(true);

  const dispatch = useDispatch();

  const dispatchIfUser = (action) => {
    if (user) {
      dispatch(action);
    }
  };

  useEffect(() => {
    dispatchIfUser(FetchAllMokedWorkers());
  }, [dispatch, mokedWorkerUpdater, user]);

  useEffect(() => {
    dispatchIfUser(FetchAllChatMessage());
  }, [dispatch, chatMessageUpdater, user]);

  useEffect(() => {
    dispatchIfUser(FetchAllShiftPageTypes());
  }, [dispatch, user]);

  useEffect(() => {
    dispatchIfUser(FetchSiteInformation(user?.result?._id));
  }, [dispatch, siteInfoUpdater, user]);

  useEffect(() => {
    dispatchIfUser(FetchAllFarmsInformations());
  }, [dispatch, farmInfoUpdater, user]);

  useEffect(() => {
    dispatchIfUser(FetchAllOtherSiteInformations());
  }, [dispatch, otherSiteInfoUpdater, user]);

  useEffect(() => {
    dispatchIfUser(
      FetchAllMalfunctions(user?.result?.userName, user?.result?.isMoked)
    );
  }, [dispatch, malfunctionUpdater, user]);

  useEffect(() => {
    dispatchIfUser(FetchAllImageLists());
  }, [dispatch, user]);

  useEffect(() => {
    dispatchIfUser(FetchAllMalfunctionTypes());
  }, [dispatch, malfunctionTypeUpdater, user]);

  useEffect(() => {
    dispatchIfUser(FetchAllMokedScansSchemas());
    dispatchIfUser(FetchAllMokedScans());
  }, [dispatch, mokedScansSchemaUpdater, user]);

  useEffect(() => {
    dispatchIfUser(
      FetchMalfunctionArchive(user?.result?.userName, user?.result?.isMoked)
    );
  }, [dispatch, malfunctionUpdater, user]);

  useEffect(() => {
    dispatchIfUser(FetchAllScouts());
  }, [dispatch, user]);

  return {
    setMokedWorkerUpdater,
    setSiteInfoUpdater,
    setMalfunctionUpdater,
    setMalfunctionTypeUpdater,
    setChatMessageUpdater,
    setMokedScansSchemaUpdater,
    setFarmInfoUpdater,
    setOtherSiteInfoUpdater,
  };
};
