import './imageList.css'
import React, { useState } from 'react'
import ImageDisplay from './ImageDisplay/ImageDisplay'

export default function ImageList({imageList}) {

  const [openPic, setOpenPic] = useState()

  if(imageList.length === 0){
    return(
      <h3>אנא המתן...</h3>
    )
  }

  return (
    <div className='imageListWrapper'>
      <div className='imageDisplayWrapper'>
        {imageList.map(image => {
            return (
                <div key={image._id} onClick={() => setOpenPic(image)} className="imageListContainer">
                  <p>{image.imageName}</p>
                  <h3>{image.companyName}</h3>
                </div>
            )
          })}
        </div>
        {openPic && 
        <ImageDisplay image={openPic} setOpenPic={setOpenPic} />}
    </div>
  )
}
