import "./leftBar.css";

import { useDispatch } from "react-redux";

import { useEffect, useState } from "react";
import { Alert } from "@material-ui/lab";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CheckCircleOutlined } from "@material-ui/icons";

import { CreateEntry } from "../../actions/entries";

import SiteInformation from "./SiteInformation/SiteInformation";
import ActiveEntryInformation from "./ActiveEntryInformation/ActiveEntryInformation";
import SiteMalfunction from "./SiteMalfunction/SiteMalfunction";

export default function LeftBar({
  sitesInformation,
  siteNameValue,
  fetchActiveEntries,
  pickedSite,
  setPickedSite,
  setActiveEntryEditId,
  activeEntryEditId,
  setGetActiveEntriesUpdater,
}) {
  const [exitAlert, setExitAlert] = useState(false);
  const [switchState, setSwitchState] = useState("מידע כללי");

  const dispatch = useDispatch();

  const information = sitesInformation?.filter(
    (e) => e.siteName === siteNameValue
  )[0];
  const pickedSiteEntries = fetchActiveEntries.filter(
    (e) => e[0] === pickedSite
  )[0];

  useEffect(() => {
    if ((!activeEntryEditId && siteNameValue) || !pickedSiteEntries) {
      setPickedSite("");
    }
  }, [siteNameValue, setPickedSite, activeEntryEditId, pickedSiteEntries]);

  const stopAlert = () => {
    setExitAlert(false);
  };

  const exitAllHandlerConfirm = () => {
    confirmAlert({
      title: <p className="approveTitle">אשר לביצוע הפעולה</p>,
      message: (
        <p className="approveMessage">
          {" "}
          האם להוציא את כל העובדים מאתר {pickedSite}?
        </p>
      ),
      buttons: [
        {
          label: <p>בטוח</p>,
          onClick: () => exitAllHandler(),
        },
        {
          label: <p>בטל</p>,
        },
      ],
    });
  };

  let now = new Date();

  let activeEntryToEntry = "";

  let [SiteName, EntryData] = pickedSiteEntries || "";

  const exitAllHandler = () => {
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "2-digit",
      timeZone: "Asia/Jerusalem",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    SiteName = now.toLocaleString("en-US", options).replace(/,/g, "");

    EntryData.map((entry) => {
      activeEntryToEntry = entry;
      activeEntryToEntry.updated_At = SiteName;

      return dispatch(CreateEntry(entry._id, activeEntryToEntry));
    });

    setGetActiveEntriesUpdater(false);
  };

  return (
    <div className="leftBar">
      <div className="leftBarWrapper">
        <div className="leftBarTop">
          <h3 className="siteNameTitle">{pickedSite || siteNameValue}</h3>
          {siteNameValue && !pickedSite && (
            <div className="siteNameInfo">
              <button
                className="optionSiteInfo"
                onClick={(e) => setSwitchState("מידע כללי")}
              >
                מידע כללי
              </button>
              <button
                className="optionFunctionInfo"
                onClick={(e) => setSwitchState("פתיחת תקלה")}
              >
                פתיחת תקלה
              </button>
            </div>
          )}
          {pickedSite && (
            <button className="clearAllSite" onClick={exitAllHandlerConfirm}>
              הוצא את כל העובדים
            </button>
          )}
        </div>
        <div className="leftBarData">
          {exitAlert && (
            <div className="exitAlert">
              {" "}
              <Alert
                icon={
                  <CheckCircleOutlined
                    className="exitIcon"
                    fontSize="inherit"
                  />
                }
                severity="error"
              >
                היציאה נרשמה בהצלחה{" "}
              </Alert>{" "}
            </div>
          )}
          {pickedSiteEntries && pickedSite ? (
            <ActiveEntryInformation
              pickedSiteEntries={pickedSiteEntries}
              setPickedSite={setPickedSite}
              setActiveEntryEditId={setActiveEntryEditId}
              setGetActiveEntriesUpdater={setGetActiveEntriesUpdater}
              exitAlert={exitAlert}
              setExitAlert={setExitAlert}
              stopAlert={stopAlert}
              activeEntryToEntry={activeEntryToEntry}
              EntryData={EntryData}
              now={now}
            />
          ) : siteNameValue && switchState === "מידע כללי" ? (
            <SiteInformation information={information} />
          ) : switchState === "פתיחת תקלה" && information.malfunctions[0] ? (
            <SiteMalfunction information={information} />
          ) : null}
        </div>
      </div>
    </div>
  );
}
