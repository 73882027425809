import "./activeEntriesItem.css"
import { MenuRounded, PeopleOutlineRounded } from "@material-ui/icons"

export default function ActiveEntriesItem({ activeEntry, chosenMokedType, setPickedSite, setSiteNameValue }) {

    let [SiteName, EntryData] = activeEntry

    EntryData = EntryData.filter(entryData => chosenMokedType !== "כל הכניסות" && chosenMokedType !== "כל הכניסות"  ? entryData.mokedType === chosenMokedType : EntryData)

    let EmployLength = EntryData.map((entry) => entry.employName)

    EmployLength = Object.keys(EmployLength).length

    let CreatedAt = EntryData.map((create) => create.created_At)

    return (
        
            <div className="activeEntryList fadeInDown"> 
                {Object.keys(EntryData).length > 0 &&
                <div className="activeEntryItem fadeIn">
                    <div className="activeEntryItemTop">
                    <p className="activeSiteName">{SiteName}</p>
                    <MenuRounded className="burgerIcon" onClick={e => {setPickedSite(SiteName); setSiteNameValue("")}} />
                    </div>
                    <div className="activeEntryItemBottom">
                        <div className="activeEntryItemBottomRight">
                            <PeopleOutlineRounded className="employLengthIcon" />
                            <p className="employLengthText">{EmployLength}</p>
                        </div>
                        <div className="activeEntryItemBottomLeft">
                            <p className="lastUpdated">{CreatedAt.reverse()[0].slice(16,21)}</p>
                        </div>
                    </div>
                </div>
                }
            </div>
        
    )
}
