import "./activeEntryInformation.css"

import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css"

import { ExitToAppRounded } from "@material-ui/icons"

import { useDispatch } from "react-redux"

import { CreateEntry } from "../../../actions/entries"


export default function ActiveEntryInformation({ setActiveEntryEditId, setGetActiveEntriesUpdater, setExitAlert, stopAlert, activeEntryToEntry, EntryData, now }) {

    const dispatch = useDispatch()

    const options = {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "2-digit",
        timeZone: 'Asia/Jerusalem',
        hour12: false,
        hour: '2-digit',
        minute:'2-digit',
        second: '2-digit' 
    }

    let SiteName = now.toLocaleString('en-US', options).replace(/,/g,"")

    return (
        <div>
            {EntryData.map((entry, index) => {
                return(
                <ul className="activeEntryInfoList" key={entry._id}>
                    <li className="activeEntryInfoItem activeEntryInfoItemName" onClick={() => setActiveEntryEditId(entry._id)} >
                        {entry.employName}
                    </li>
                    <li className="activeEntryInfoItem">
                        {entry.companyName}
                    </li>
                    <li className="activeEntryInfoItem activeEntryInfoItemHour">
                        {entry.created_At.slice(16,21)}
                    </li>
                    <li className="activeEntryInfoItem">
                        {entry.creator}
                    </li>
                    <ExitToAppRounded className="employExitIcon" onClick={() => {

                        confirmAlert({
                            title: <p className="approveTitle">אשר לביצוע הפעולה</p>,
                            message: <p className="approveMessage"> האם להוציא את {entry.employName}?</p>,
                            buttons: [
                            {
                                label: <p>בטוח</p>,
                                onClick: () => {

                                    activeEntryToEntry = entry
                                    activeEntryToEntry.updated_At = SiteName
                                    setGetActiveEntriesUpdater(false)
                                    dispatch(CreateEntry(entry._id, activeEntryToEntry))
                                    setExitAlert(true)
                                    setTimeout(stopAlert, 3000)
                                }
                            },
                            {
                                label: <p>בטל</p>
                            }
                            ]
                        })

                    }}/>
                </ul>
                )
            })}
        </div>
    )
}
